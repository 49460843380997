import React from "react";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";
import "../styles/Intro.css";

class Intro extends React.Component {
    render () {
        return (
            <div className = "intro-meta">
                <Typist avgTypingDelay={75}>
                    <span className = "intro">
                        {"Hi, I'm "}
                        <span className = "highlight">{"Jacob"}</span>
                        {"; welcome to my page."}
                    </span>
                </Typist>
                <div className = "bio">
                    <span>I'm a Software Developer currently studying at the
                    <span className = "highlight"> University of Central Florida</span>.
                     I'm also a former
                    <span className = "highlight"> Amazon </span> Software Development Engineer intern who will be joining
                    <span className = "highlight"> Google </span> as a Software Engineer upon graduation.</span>
                </div>
            </div>
        );
    }
}

export default Intro;