import React from "react";
import "../styles/Cards.css";
import pdf from '../Resume.pdf';
import GitHub from '../GitHub.png';
import Linkedin from '../Linkedin.png';
import resumelogo from '../resumelogo.png';
class Cards extends React.Component {
    render () {
        return (
            <div className = "card-meta">
                <a className = "card" href={pdf} target="_blank" rel="noreferrer">
                    <p className = "card-title">Resume</p>
                    <img className = "card-image" src={resumelogo}/>
                </a>
                <a className = "card" href="https://github.com/SageJacob" target="_blank" rel="noreferrer">
                    <p className = "card-title">GitHub</p>
                    <img className = "card-image" src={GitHub}/>
                </a>
                <a className = "card" href = "https://www.linkedin.com/in/~jacob/" target="_blank" rel="noreferrer">
                    <p className = "card-title">Linkedin</p>
                    <img className = "card-image" src={Linkedin}/>
                </a>
            </div>
        );
    }
}

export default Cards;