import './App.css';
import Intro from './components/Intro.js';
import Cards from './components/Cards.js';
import {useEffect} from 'react';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className = "meta">
      <Intro/>
      <Cards/>
    </div>
  );
}

export default App;
